// .core
import React, { useEffect, useState } from 'react';
// components
import {Button, Card, Heading, Input, Loader, showToastErr, showToastOk} from 'components';
// i18n
// import { t } from 'i18n'
// styles
import css from './RegisterPage.module.scss';
import { Logo } from '../Partials/Logo';
import Image from 'assets/images/image.png';
import { Link, useHistory } from 'react-router-dom';
import { SocialAuthButtons } from '../Partials/SocialAuthButtons';
import { observer } from 'mobx-react';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { IRegistrationForm, RegistrationForm } from '../../../utils';
import { AuthService } from '../../../services/AuthService';
import { changeTitle } from '../../routes';
import APIClass from '../../../api';
import Footer from '../Partials/Footer';
import ReCAPTCHA from "react-google-recaptcha";

const registerForm = new RegistrationForm();

export const RegisterPage = observer(() => {
  const history = useHistory();
  const [checkedForInvitation, setCheckedForInvitation] = useState<boolean>(false);
  const [isInvitation, setIsInvitation] = useState<boolean>(false);
  const [regFormDefault, setRegFormDefault] = useState<IRegistrationForm | undefined>();

  const recapthaRef = React.useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (!checkedForInvitation) {
      if (window.location.search.includes('invitation=')) {
        const params = new URLSearchParams(window.location.search.substr(1));
        const invitation = params.get('invitation');
        if (invitation) {
          APIClass.AuthEndpoint.CheckInvite(invitation).then(res => {
            if (res?.success) {
              setRegFormDefault(res.content as IRegistrationForm);
              setIsInvitation(true)
            }
            setCheckedForInvitation(true);
          });
        }
      } else {
        setCheckedForInvitation(true);
      }

    }
  }, [checkedForInvitation]);

  changeTitle('Register');

  const register = async (values: IRegistrationForm, {setErrors}: FormikHelpers<IRegistrationForm>) => {
    if (recapthaRef) {
      const token = await 
      recapthaRef.current!.executeAsync();
      
      recapthaRef.current!.reset();
      if(token) {
          values.captha = token;
          AuthService.register(values).then(message => {
            history.push('/dashboard');
            AuthService.checkUser().then(() => {
                showToastOk(message);
            });
        }).catch(res => {
            setErrors(res.content.errors);
        });
          } else {
              showToastErr('reCAPTCHA verification failed!')
          }
      }
  };

  const { formSubmitting } = AuthService;

  function preventDefault(e: React.FormEvent) {
    e.preventDefault();
  }

  return (
    <div className={css.root}>
      <div className={css.logoFlex}>
        <Logo/>

        <Link to="/login">
          <Button size="small" color="neutral" label="Log in"/>
        </Link>
      </div>
      <Card className={css.card} classes={{ content: css.cardFlex }}>
        <img src={Image} alt="leftImage"/>
        {checkedForInvitation ? (
          <div className={css.registerContent}>
          <Heading title="Registration"/>
          <p>
            You can register via following services
          </p>
          <SocialAuthButtons/>
          <p>or via your</p>
          <Formik
            initialValues={regFormDefault || registerForm.INITIAL_VALUES}
            validationSchema={registerForm.VALIDATION}
            onSubmit={register}
          >
            {(form: FormikProps<FormikValues>) => (
              <Form onSubmit={preventDefault}>
                <Input.Field
                  className={css.input}
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Enter your name"
                  bAutoFocus={!isInvitation}
                />
                <Input.Field
                  className={css.input}
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                />
                <Input.Field
                  className={css.input}
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  bAutoFocus={isInvitation}
                />
                <Input.Field
                  className={css.input}
                  name="tou"
                  type="checkbox"
                  label={<>I agree with the <a href="https://www.webswing.org/terms-of-use" target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>Terms of use</a></>}
                />
                <Input.Field
                    className={css.hide}
                    label="User"
                    name="user"
                    type="text"
                />
                <Button type="submit" className={css.registerButton} label="Register" onClick={form.submitForm}/>
                {formSubmitting && <Loader className={css.spinner} size={15}/>}
              </Form>
            )}
          </Formik>
          <ReCAPTCHA
              ref={recapthaRef}
              size="invisible"
              sitekey="6LdFosIkAAAAABAQ88_MBQRCmA81OHY7PzJ7QsYr"
          />
        </div>
          ) : (<Loader.Spinner />)}
      </Card>
      <Footer center />
    </div>
  );
});
