import { t } from 'i18n'

export interface IMenuItems {
  name: string
  path: string
  icon?: string
  permission: string | null
}

interface IMenuItemsWithSub extends IMenuItems {
  subItems?: IMenuItems[]
}

export const MENU_ITEMS: IMenuItemsWithSub[] = [
  {
    name: t.DASHBOARD,
    path: 'dashboard',
    icon: 'dashboard',
    permission: null,
  },
  {
    name: t.DOWNLOADS,
    path: 'downloads/distributions',
    icon: 'cloud',
    permission: null,
  },
  {
    name: t.ISSUES,
    path: 'support',
    icon: 'help',
    permission: null,
  },
  {
    name: t.SETTINGS,
    path: 'settings',
    icon: 'settings',
    permission: null,
  },
]
