// .core
import React from 'react'
import ReactDOM from 'react-dom'
// components
import { Button } from '../../../../basic/Button/Button'
import { Icon } from '../../../../basic/Icon/Icon'
// i18n
import { t } from 'i18n'
// libraries
import cx from 'classnames'
// styles
import css from './DialogDefault.module.scss'

export interface IDialogDefaultProps {
  bOpen?: boolean
  className?: string
  classNameContent?: string
  classNameWrap?: string
  title?: string
  disabledButtons?: boolean

  onToggle?(bOpen?: boolean): void

  onConfirm?(): void

  labelOk?: string
  labelCancel?: string
  footer?: boolean
  header?: boolean
}

export class DialogDefault extends React.PureComponent<IDialogDefaultProps> {
  static defaultProps: IDialogDefaultProps = {
    footer: true,
    header: true,
    title: t.DIALOG,
  }

  // =============== I N I T ===============
  //   componentDidMount() {}
  //   componentWillUnmount() {}

  // =============== E V E N T S ===============
  onToggle = () => {
    const { bOpen, onToggle } = this.props
    onToggle?.(!bOpen)
  }

  onConfirm = () => {
    const { onConfirm } = this.props

    onConfirm?.()
  }

  render() {
    const {
      bOpen,
      children,
      className,
      classNameContent,
      classNameWrap,
      title,
      onToggle,
      labelOk,
      labelCancel,
      footer,
      header,
      disabledButtons,
    } = this.props
    if (!bOpen) return null

    return ReactDOM.createPortal(
      <div className={cx(css.wBackground, classNameWrap)} onClick={this.onToggle}>
        <div className={cx(css.wDialog, className)} onClick={e => e.stopPropagation()}>
          {/* HEADER */}
          {header ? (
            <div className={cx(css.wHeader)}>
              <span>{title}</span>
              <Icon name="close" onClick={onToggle} />
            </div>
          ) : null}

          {/* CONTENT */}
          <div className={cx(css.wContent, classNameContent)}>{children}</div>

          {/* FOOTER */}
          {footer ? (
            <div className={cx(css.wFooter)}>
              <Button
                color="neutral"
                label={labelCancel || 'Cancel'}
                size="small"
                onClick={this.onToggle}
                bDisabled={disabledButtons}
              />
              <Button
                color="danger"
                label={labelOk || 'Confirm'}
                size="small"
                onClick={this.onConfirm}
                bDisabled={disabledButtons}
              />
            </div>
          ) : null}
        </div>
      </div>,
      document.body
    )
  }
}